// Stile für die Themenbox
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


.topicbox-header {
	background: $cyan no-repeat 26px center;
	color: #fff;
	margin: 0 0 1px;
	padding: 0 19px 0 60px;

	> a {
		text-decoration: none;
	}

	.js & {
		cursor: pointer;
	}
}

.topicbox-header-contact {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAVCAYAAABLy77vAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAFwSURBVHjaYvj//z8DECsAcTUQHwPiH1C6GirOQAwGCCCYIUv/YwdLiTUMIIAYoDbjA9XEGAQQQEwMDAzeDPgBsrwVEM8B4ntAfAKIK4FYEiQBEEAM0DDBB35AbdUB4j1A/BdJ7isQNwOxCkAAMUADFh84BjVoKg75j0DcBhBAIK9tJeA1mLwXDnk+kBxAAJESa8/wuPoGQAAxkJCO5uMw5DsQTwAIIAZiExwQmwPxGTRDQAE/BYj1AAKIEZyYGBgUgDgaGtVGQHwOGjZLgfgBUngYAHEEENsC8Rsg3g7Ea0BsgAAiJ2XLQl2nD8QCMHGAACIlZXMDcTEoYKHiv6DpyhGIOQACiJR0lA7E37DInwfiKIAAIiVlH8KjZhNAAIEC+y8wsJjwJMh/QMwMxB+hiQ8beAIQQExQhQwEDAIBVjxq2AACiBSD8Kn7CxBAIIP+EzDoPxEG/QMIICYG4gEjPjmAAGIhwaAfeMLpB0CAAQA/hCrmuZviTQAAAABJRU5ErkJggg==);
}

.topicbox-header-downloads {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADeSURBVHjaYvz//z8DFoBVEAkwogsABBALsQoJAYAAwmUQyS4CCCAmBioBgACimtcAAohqXgMIIKp5DSCAqGYQQAAhG8QG9dJ/IvTB1KnABAACCNmgX0CsB8SPiDAIpLYYiO/ABAACiAGUstGwHBBf+o8bPARiS3R9AAGEzSAQ5gHiA1gM2QjE/Nj0AAQQLoNAmA2Il0IN+AnERXjUMgAEED6DYLgam1fQMUAAMf6HZH9GCmP/P0AAUS0dAQQQ1QwCCCAWIvMWQQAQQFRzEUAAsRRf+UtpQAsD8VuAAAMA3TAAbyTjx9EAAAAASUVORK5CYII=);
}

.topicbox-header-events {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAARCAYAAADZsVyDAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAElSURBVHjaYvz//z8DEgBxGKnAZgAIICYGGgGAAGJEczHVAEAAgVwsBcQTgPglEH8D4j9Qb4HwXyj/D5T9F0nuH5q6/1AzQGZJAQQQA9DFE/5TH0wACCBQUIBsEaNySLwCCCBQUPDSIIh5AQKIZpEHEEAs0EBnQE6DFAKweQABxERIAQ7+fyzyKAAggJANZsRj+H8suZMRi+FwMwACiAmaHnG5nhE9qxKRL0DgH0AAMSHZykaF8IWZ8R8ggEAG/4IlEVzZHocvsPkEZsYvgAACGfwZyokkwkBkNrbggZnxGSCAkLP0UyDOB2IxIOYEYmYgZsKCwWkfDYtA9T6FZWmAAAIJSkENfwnE34D4D1Ke/wvl/4GyYRgE/qGp+w81A2SWFECAAQCOpTATfv9roQAAAABJRU5ErkJggg==);
	background-position: 24px center;
}

.topicbox-header-forms {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAVCAYAAABLy77vAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAACOSURBVHjaYvwPBAxUAAABxMRAJQAQQFQzCCCAqGYQQACxoAswzn/ESIzG/4lyKGELEEAsxCgixjKAAGIhViEhABBAjNSKfoAAolpgAwQQ1QIbIICoFtgAAUQ1rwEEENUMAgggqhkEEEBUMwgggKhmEEAAUS1lAwQQ1VI2QABRzWsAAUQ1gwACiGoGAQQYAK1GHy79V1CcAAAAAElFTkSuQmCC);
}

.topicbox-header-links {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADTSURBVHjaYvj//z8DFqyCQ5ygGoAAwqaw6D8EEDLwP1QtijhAAKErsgTinyQY+BOqBy4OEEDICviB+OF/7ADZEHTwEKoXrAYggJAN3PgfN8Bn4H+oXrAagABCDzdyDYSHJ0AAoYcbJQaCwxMggJgYGBicgJiNgXIAMsMJIIBgNi+lggtBZjAABBBMIRsQH6DAwANQMxgAAgg5lnmA+BIZBl6C6gWrAQgg9MQqh5QWiUnYD6F64OIAAYRNoS4JBuqiiwMEECPYVOwx9ouIWMVQAxBgAP5dQZS+5XwUAAAAAElFTkSuQmCC);
	background-position: 25px center;
}

.topicbox-header-mapmarker {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAWCAYAAAD0OH0aAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAALtJREFUeNpi/P//PwMUyAJxMBC7A7ExVOwsEO8E4rVA/BgsAtIAxJZAvOE/brABqgasQZaAYmRNsiANBf+JBwVMUDcTC9wZgbpeARmiRGp4zcRAImCCBh2x4CwTNJyJBTvJClaSIw6GZaFx8hOqCERvh4rJwtQha4Dhq1ANV7HIMWAL1jtoNEawUqzhPhpNfRtuotEogBEpx8EAGxB/A2IuIP5FjA0gRdewKcalAQTW4Eut2MBWXBoAAgwA1KhRag98FUYAAAAASUVORK5CYII=);
}

.topicbox-header-news {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAYAAACdkl3yAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAEESURBVHjaYvr//78UEE8A4pdA/O0/AvwF4j9QsVdAPBuIZYH4MhDPguq7CNUrBRBALAwMDGVAnM+ACZigNCcUpwDxVyDWBeL/QPwNiPWhbAaAAGKAuoRYAFIrho0NEEAgW3kZiAcgtZ+xsQECCOQ1NiSFjDgM+A+lQWp/YWMDBBATHs3YACOSoShsgABiwWEzyQAggJgYqAQAAogFi9PxhRFOABBAVPMaQABRzWsAAcSC5gpCXvuPpAaFDRBAVPMaQABRLR0BBBDVvAYQQExIyRwmiQ3DwC+kLIXCBgggJqSMRwz4jJTJUdgAAQQyaDkJBoHUvsLGBgggBgpKSAbkEhIgwADfZPh45/H4RAAAAABJRU5ErkJggg==);
}

.topicbox-header-phone {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAATCAYAAACUef2IAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGCSURBVHjaYvz//z8DDsAMxAJA/JaBDAAQQCxIbAkgDgFiXSDWBGINIP4CxG+A+AQQzwbiy8QaDBBADCAXA7Hkfwj4A8Q//+MGDUCsCdWDFwMEEIgQQjLsH5Ihf3AY/h6IlwGxMD6DAQIIRBgB8VIg/v6fNHANiEVxGQwQQDAGOxAnoQUDyPUvgLgDiM8hif+C0j+AeBcQO2MzGCCA0AVKgfgxUlDIQcVZoT67hMXlH4DYCohZkM0CCCBs3lCFujAai5wMEB+GuhYZ3AFiH2S1AAHEQEwMo2FQuNZjcfk+aJCC1QEEEDkGw3AHNIUgB0kmTB4ggCgxmA+Ie9Fc/QiIZUHyAAFEicEgLIFm8B9oJmIACCAmBsrAKSzlix4QswIEELkGg/RdAmJZLHKgsuY3QAAx4ind8AGQy94BMR9ysQOlGYFYCyCAyHXxXyCOAuJDSGKMUAwCrwECiNLI4wDiTrQILATJAQQQpQbDMCiXXgXiRTAxgAAiN4yxAWHk2gYgwADNn4f3c2RBjwAAAABJRU5ErkJggg==);
	background-position: 25px center;
}

.topicbox-header-services {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAI8SURBVHjaYvz//z8DFQAjEOM0CCCAmKhkwUQgNsOlACCAGEA+oQCzAHHnfwj4AMRm2NQBBBAlFrAD8az/qACrRQABRK4FzEDc8R87wLAIIICobQFWiwACiBhDBYHYHIhVgJgNKnbuP2HwBoiVQeoBAoiFQMqJBGJPII4F4ufQlHQKiIOAmA+IL+JKT0B8GIhB5jMCBBAu14Nc3AzEd/C4NAaqFhvYBMRG0KBlAAggXGHeD1X8j0CQFGKxaAkQawMxK8xMgADCZgko/F/8Jx6A1MdD2euA2BiImZDNBAggbJbMB+LfaAY9B+JLeCxngOYZc2gGRTETIIDQLZAC4h9oBjwFYgOovAIQP8FiSSIQc+NKoQABhC4QgcUX9WhqzLBYcgJfNgAIICa0wrIYmuxg4CuW5CmEpcTlxVfYAgQQso0iQPwRyXXfgLgISZ4DiO2AeB+aL34BcRU+nwAEEHpQwcBPIG5EStKlQHwSiF8D8XckdaAkfhOIHYCYEZclAAGEzFkG1QiK+HwkTXlA/BBHqgJZWEOoaAIIIBhDFIjfQzWWQC1gh6aaGzgy5R+04MSJAQIIxggF4q/QlMQKxSDf3APiv2iGfwLiR4TiARkDBBCMAQrvSqjhoHIrAYgfoIU9qPi+C8TZQKwPK5eIwQABBCIkoRYwQVNQMTQDwhLAHCAOAWI5IOYkp5IDCCBQ2DMDk/JfIAbRVUDsCuVPAOKjQPyG0pYGQAAxIjWJnIFYCYi3QusOqrSVQAAgwABsRAevjLzLDAAAAABJRU5ErkJggg==);
	background-position: 24px center;
}

.topicbox-header-inner {
	display: block;
	padding: 11px 0 6px;

	.js & {
		@extend .arrow-medium-white-down;
		background-position: right center;
	}
}

.topicbox-header-inner-open {
	.js & {
		@extend .arrow-medium-white-up;
		background-position: right center;
	}
}

.topicbox-content {
	.js & {
		display: none;
	}
}

// Dieses Element wird benötigt, damit das Sliding ohne Ruckeln läuft
.topicbox-content-inner {
	padding: 12px 0;

	:last-child {
		margin-bottom: 0;
	}
}

.topicbox-content-padding {
	padding: 0 23px;
}

.topicbox-content-address {
	margin-top: 20px;

	&:first-child {
		margin-top: 0;
	}
}
