// Stile für Grid Elements
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


// ***** Zwei Spalten Aufmacher Startseite *************************************
// wird auch in den Seitentemplates der Kapiteleinstiegsseite und Doorpage verwendet
.twocols-col-left {
	border-right: 1px solid #fff;
	float: left;
	width: 479px;
}

.twocols-col-right {
	float: right;
	width: 480px;
}



// ***** Vier Boxen auf Startseite *********************************************
.home-fourboxes {
	padding: 20px 0 0;

	.h2 {
		color: $cyan;
		margin-bottom: 15px;
	}

	DIV.csc-textpic .csc-textpic-imagewrap .csc-textpic-image {
		margin-bottom: 10px;
	}
}

.four-boxes-header {
	border-right: 1px dotted #c2c2c1;
	margin: 0;
	padding: 0 0 20px;
	width: ($siteWidth / 2) - 51px - 1px;
 
}

.home-fourboxes-col {
	float: left;
}

.home-fourboxes-col-left {
	border-right: 1px dotted #c2c2c1;
	padding-right: 51px;
	width: ($siteWidth / 2) - (2 * 51px) - 1px;
}

.home-fourboxes-col-right {
	padding-left: 51px;
	width: ($siteWidth / 2) - (2 * 51px);
}

.home-fourboxes-col-top {
	.h2 {
		margin-top: 0;
	}
}

.home-fourboxes-col-bottom-inner {
	border-top: 1px dotted #c2c2c1;

	&:last-child {
		margin-bottom: 0;
	}
}
