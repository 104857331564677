// Stile für die Teaser auf der Doorpage
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


.doorteaser {
	@include linear-gradient-toleftbottom($doorteaserGradientTopRight, $doorteaserGradientBottomLeft);
	border-top: 1px solid #fff;
	height: 220px;
	overflow: hidden;

	.doorteaser-link:first-child & {
		border-top: none;
	}

	.no-cssgradients & {
		background: $doorteaserGradientTopRight url(../../Public/Images/StyleSheets/no-cssgradients-doorpage.png);
	}
}

.doorteaser-link {
	color: inherit;
	text-decoration: none;
}

.doorteaser-header {
	@include border-radius-position(bottom-right, 15px);
	color: #fff;
	line-height: 100%;
	margin-right: 40px;
	padding: 22px 0 18px 51px;
	text-transform: uppercase;
	background: $cyan;
}

.doorteaser-header-arrow {
	background-position: right top;
	display: inline-block;	/* für Chrome und Opera */
	padding-right: 49px;
}

.doorteaser-subheader {
	color: $cyan;
	@include line-height(22);
	margin: 21px 40px 14px 51px;
}

.doorteaser-text {
	margin: 0 40px 0 51px;
}
