// Stile für die Sitemap
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen

.sitemap {
	padding: 0;
}



// ***** Standardeinstellungen und Hauptnavigation *****************************
.sitemap-level1 {
	@include font-size(18);
	font-family: DINWeb, Arial, Helvetica, sans-serif;
	margin: 20px 0 40px;
}

.sitemap-level2 {
	margin: 1px 0 20px;
}

.sitemap-level3 {
	margin: 0;
}

.sitemap-level4 {
	@include font-size(14);
	border-top: 1px dotted #c2c2c2;
	margin: 0;
	padding: 10px 0;
}

.sitemap-item {
	margin-bottom: 1px;
}

.sitemap-link {
	display: block;
	padding: 11px 0 6px 55px;
	text-decoration: none;

	&:hover, &:active, &:focus {
		text-decoration: underline;
	}
}

.sitemap-link-level1 {
	background-color: #009de0;
	background-position: 24px center;
	color: #fff;
}

.sitemap-link-level2 {
	background-color: #5ac5f1;
	background-position: 55px center;
	color: #fff;
	padding-left: 90px;
}

.sitemap-link-level3 {
	background-position: 90px center;
	border-top: 1px dotted #c2c2c2;
	padding-left: 125px;
}

.sitemap-link-level3-first {
	border-top: none;
}

.sitemap-link-level4 {
	background-position: 125px center;
	padding: 4px 0 3px 160px;
}



// ***** Anpassungen für die Meta- und Fußnavigation ***************************
.sitemap-link-level1-meta {
	background-color: $textColor;
}

.sitemap-link-level2-meta {
	background-color: #9d9fa2;
}

.sitemap-link-level3-meta,
.sitemap-link-level4-meta {
	color: $textColor;
}
