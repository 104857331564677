// Mixins für Präfix-Ausgabe
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


@mixin border-radius($value) {
	-webkit-border-radius: $value;	// bis Safari 4.0
	   -moz-border-radius: $value;	// bis Firefox 3.6
	        border-radius: $value;
}

@mixin border-radius-position($position, $value) {
	$mozPosition: topleft;
	@if $position == top-left {
		$mozPosition: topleft;
	} @else if $position == bottom-left {
		$mozPosition: bottomleft;
	} @else if $position == top-right {
		$mozPosition: topright;
	} @else if $position == bottom-right {
		$mozPosition: bottomright;
	}

	-webkit-border-#{$position}-radius: $value;
	   -moz-border-#{$mozPosition}-radius: $value;
	        border-#{$position}-radius: $value;
}

@mixin box-shadow($len1, $len2, $len3, $len4, $color, $inset: false) {
	@if $inset == true {
		-webkit-box-shadow: inset $len1 $len2 $len3 $len4 $color;	// bis Safari 5.0 / Chrome 9.0
		   -moz-box-shadow: inset $len1 $len2 $len3 $len4 $color;	// bis Firefox 3.6
		        box-shadow: inset $len1 $len2 $len3 $len4 $color;
	} @else {
		-webkit-box-shadow: $len1 $len2 $len3 $len4 $color;
		   -moz-box-shadow: $len1 $len2 $len3 $len4 $color;
		        box-shadow: $len1 $len2 $len3 $len4 $color;
	}
}

@mixin box-sizing($value: border-box) {
	-webkit-box-sizing: $value;
	   -moz-box-sizing: $value;
	        box-sizing: $value;
}

@mixin font-size($sizeValue: 12) {
	font-size: $sizeValue + px;
	font-size: ($sizeValue / 10) + rem;
}

@mixin hyphens($value: auto) {
	-webkit-hyphens: $value;
	   -moz-hyphens: $value;
	    -ms-hyphens: $value;
	     -o-hyphens: $value;
	        hyphens: $value;
}

@mixin linear-gradient($topColor, $bottomColor) {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$topColor}', endColorstr='#{$bottomColor}');
	background-image: -webkit-linear-gradient($topColor, $bottomColor);
	background-image:    -moz-linear-gradient($topColor, $bottomColor);
	background-image:      -o-linear-gradient($topColor, $bottomColor);
	background-image:         linear-gradient($topColor, $bottomColor);
}

@mixin linear-gradient-rgba($topColor, $bottomColor, $opacity) {
	background-image: -webkit-linear-gradient(rgba($topColor, $opacity), rgba($bottomColor, $opacity));
	background-image:    -moz-linear-gradient(rgba($topColor, $opacity), rgba($bottomColor, $opacity));
	background-image:      -o-linear-gradient(rgba($topColor, $opacity), rgba($bottomColor, $opacity));
	background-image:         linear-gradient(rgba($topColor, $opacity), rgba($bottomColor, $opacity));
}

@mixin linear-gradient-toleftbottom($topColor, $bottomColor) {
	background-image: -webkit-linear-gradient(right top, $topColor, $bottomColor);
	background-image:    -moz-linear-gradient(right top, $topColor, $bottomColor);
	background-image:      -o-linear-gradient(right top, $topColor, $bottomColor);
	background-image:         linear-gradient(to left bottom, $topColor, $bottomColor);
}

@mixin linear-gradient-torightbottom($topColor, $bottomColor) {
	background-image: -webkit-linear-gradient(left top, $topColor, $bottomColor);
	background-image:    -moz-linear-gradient(left top, $topColor, $bottomColor);
	background-image:      -o-linear-gradient(left top, $topColor, $bottomColor);
	background-image:         linear-gradient(to right bottom, $topColor, $bottomColor);
}

@mixin line-height($sizeValue) {
	line-height: $sizeValue + px;
	line-height: ($sizeValue / 10) + rem;
}

@mixin opacity($value) {
	filter: alpha(opacity=$value*100);
	opacity: $value;
}

@mixin transition($element, $time, $easing: ease-in-out) {
	-webkit-transition: $element $time $easing;
	   -moz-transition: $element $time $easing;
	    -ms-transition: $element $time $easing;
	     -o-transition: $element $time $easing;
	        transition: $element $time $easing;
}

@mixin user-select($value: none) {
	-webkit-user-select: $value;
	   -moz-user-select: $value;
	    -ms-user-select: $value;
	     -o-user-select: $value;
	        user-select: $value;
}
