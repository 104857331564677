// Stile für die Aufmacher auf der Startseite
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


.leadstory {
	@include box-sizing;
	border-top: 1px solid #fff;
	height: 220px;
	overflow: hidden;

	.leadstory-link:first-child & {
		@include border-radius-position(top-left, 15px);
		@include border-radius-position(top-right, 15px);
		border-top: none;

		.twocols-col-left & {
			@include border-radius-position(top-right, 0);
		}

		.twocols-col-right & {
			@include border-radius-position(top-left, 0);
		}
	}
}

.leadstory-big {
	height: 440px;
}



.leadstory-header {
	@include border-radius-position(bottom-right, 15px);
	color: #fff;
	display: inline-block;
	line-height: 100%;
	padding: 22px 62px 18px 51px;
	text-transform: uppercase;
	background-color: $cyan;

}

.leadstory-header-arrow {
	background-position: right top;
	display: inline-block;	/* für Chrome und Opera */
	padding-right: 49px;
}


.leadstory-content {
	@include border-radius-position(bottom-right, 15px);
}

.leadstory-link {
	color: inherit;
	text-decoration: none;

	.leadstory-textonly {
		background: white;
		display: block;
	}
	.leadstory-textimage {
		.leadstory-content {
			padding: 0 130px 35px 0;
			width: 625px;

			.twocols-col & {
				width: 480px;
				padding-right: 25px;
			}
			.leadstory-subheader,
			.leadstory-text {
				display: none;
			}
		}
	}
}

.leadstory-subheader,
.leadstory-text {
	font-family: DINWeb, Arial, Helvetica, sans-serif;
}

.leadstory-subheader {
	@include font-size(18);
	@include line-height(22);
	color: #fff;
	font-weight: normal;
	margin: -15px 0 0 51px;
}

.leadstory-text {
	@include font-size(14);
	@include line-height(18);
	color: #000;
	margin: 15px 50px 0 51px;
}
