// Stile für Bildergalerie
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


.imagegallery-row {
	margin-top: 15px;

	&:first-child {
		margin-top: 0;
	}
}

.imagegallery-link {
	float: left;
	display: block;
	margin-left: 15px;

	&:first-child {
		margin-left: 0;
	}
}
