// Stile für Veranstaltungen
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


.event-date {
	@include font-size(15);
	color: $cyan;
	display: block;
	font-family: DINWeb, Arial, Helvetica, sans-serif;
	margin-bottom: 5px;
}

.event-list-item {
	border-top: 1px dotted #c2c2c1;
	padding: 20px 0 0;

	&:first-child {
		border-top: none;
		padding-top: 0;
	}

	img {
		float: left;
		margin: 0 20px 10px 0;
	}

	.url {
		@extend a, .more;
	}
}

.event-list-title {
	@include font-size(18);
	@include line-height(18);
	color: $cyan;
	font-family: DINWeb, Arial, Helvetica, sans-serif;
}

.tx-cal-controller {
	.csc-textpic-above {
		margin-top: -15px;
	}
}


// ***** Allgemeines in der rechten Marginalspalte *****************************
.events-margin-header {
	@extend .topicbox-header-events;
	@include font-size(18);
	background-color: #009ee3;
	background-repeat: no-repeat;
	background-position: 338px 10px !important;
	color: #fff;
	font-family: DINWeb-Bold, Arial, Helvetica, sans-serif;
	font-weight: normal;
	margin: 0;
	padding: 10px 60px 6px;
	text-transform: uppercase;
}

.events-margin-header-dark {
	background-color: #10252e;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAZCAYAAADAHFVeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAT1JREFUeNpi/P//PwMR4BwQizNQBl6yEKlQGojFKLSMhVjL/kDpg0B8gERLooFYBWQGC4katwJxN4l6DKGWMTCRqJGbjOBjgzGYGOgIiLXsH5T+RlEKAeIlQCxMQJ0olM4AYhcsDnkDxDHEWOYHxLxEpkgpIFbEIveFWJ99hloGct0ZHOqcgfgREE9HSyRfgTiHSMcyICf9Y0Dsj0Pda6haZrR4BvEZyUkgbHjUMdIzNTKMWMvwVR2/sCUQYgz8i5TBGaB8UJbgB+LtOPSZIEz4///pfwjYDqrbcODP/7GDH0D8+z9x4BOxPtsIxCJYgl0Fmsl/AvF+Ama8JdZnuHAZVO9jYtRTmkC4SEloA5b0f5Gh/yupVQwMaAFxA4mWOZCimBEYcS+p0HJ6RUxTD+Szp0itJ7LbhMQoAggwAGhk7BNTKYEiAAAAAElFTkSuQmCC) !important;
	background-position: 338px 4px !important;
}



// ***** Monatskalender in der rechten Marginalspalte **************************
.events-calendar-outer-wrapper {
	background: #5ac5f2;
	@include linear-gradient(#5ac5f2, #0096dd);
}

.events-calendar-outer {
	background-position: 317px top;
	padding: 0 60px;
}

.events-calendar {
	width: 100%;
}

.events-calender-title {
	color: #fff;
	margin: 0;
	padding: 17px 0 3px;
	text-align: center;
	text-transform: uppercase;
}

.events-calender-header {
	@include font-size(14);
	background: #0b232e;
	@include linear-gradient(#4b616c, #0b232e);
	color: #abb4b9;
	font-family: DINWeb-Medium, Arial, Helvetica, sans-serif;
	font-weight: normal;
	padding: 10px 0 6px;
	text-transform: uppercase;
}

.events-calender-header-weekend {
	color: #53add3;
}

.events-calender-day {
	@include font-size(14);
	background: #7ed8fe;
	@include linear-gradient-torightbottom(#fdfdfe, #7ed8fe);
	border-left: 1px solid #55c4f3;
	border-top: 1px solid #55c4f3;
	color: #0096dd;
	font-family: DINWeb, Arial, Helvetica, sans-serif;
	padding: 10px 0 6px;
	text-align: center;

	&:first-child {
		border-left: none;
	}

	&.currentDayHeader {
		font-family: DINWeb-Bold, Arial, Helvetica, sans-serif;
	}

	&.eventDay {
		background: #b92e4d;
		@include linear-gradient-torightbottom(#d05f71, #b92e4d);
		color: #fff;
	}

	// muss nach .eventDay stehen, da ansonsten die Stile von .eventDay angezeigt werden
	// Fehlerhafte Implementierung in cal
	// @see http://forge.typo3.org/issues/42018
/*
	&.monthOff {
		background: #4cbdf4;
		@include linear-gradient-torightbottom(#4cbdf4, #26aded);
	}
*/

	.url {
		color: #fff;
		display: inline-block;
		text-decoration: none;
		width: 100%;
	}
}

.events-calender-day-weekend {
	background: #0172a2;
	@include linear-gradient-torightbottom(#4ec8fc, #0172a2);
	color: #fff;

	// Fehlerhafte Implementierung in cal
	// @see http://forge.typo3.org/issues/42018
/*
	&.monthOff {
		background: #008ed0;
		@include linear-gradient-torightbottom(#10aaf2, #008ed0);
		color: #53bbeb;
	}
*/
}

.events-calender-nav {
	background: #0096dd;
	margin-bottom: 20px;
	padding: 5px 60px 25px;
}

.events-calendar-nav-link {
	display: block;
	height: 10px;
	width: 13px;

	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
}

.events-calendar-nav-link-prev {
	float: left;
}

.events-calendar-nav-link-next {
	float: right;
}



// ***** Filter in der rechten Marginalspalte **********************************
.events-filter-wrapper {
	background: #495e68;
	@include linear-gradient(#495e68, #10252e);
	margin-bottom: 20px;
}

.events-filter {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAASCAYAAADrL9giAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAbZJREFUeNrk1zlLA0EUwPHNCGoZsPADSCw8EhQLsfFoxCIiiKi90dKr9/gCinaCUcsoFoLBq0qstFErxatUiGihYuP9X3hCWGPMbnaTaB78AruZfTOTzc68dbk93pCmad1a/kUUw4qPHtRgN08mfoxWNOFQyckjNKJNGvzHuEYfvNj5OqkMjbakQT+u/snEHzCBcgTxFv9lQXFJqfGCDxxgDq+oRdEfnPgLFtGBDTn+FipJgieMw4P5nxLkYOg3MAwfArhJ1lilkDAmifSE69JBrsYeWuDHSSoXKBPJ9YTtaJaOcinOZStvQMTMhcpCZ1HpqAuXWZ74HQZRhRUr/06VxnO2igqMykqbyXjGNMowK8eWQtkwkCkZyEw6AzHxwy+jEiO4TzehsmlgtxhCNUIOTT4ij55euV7YlVTZPMgz9KLe7GL0y+Lrl9Xd9sVXOXS39uO2o1OLOWJSuvpkX3dk+1UOP69hWaEDMqFUQi/AJqUACzpdgCUqhe2Od0NpXYfCBO30Gn0BnVJwOb2gOv4PMMYjxuTOLhleSrZlAQ3IW1vGwuX2eLNVxOgvWQNYw2a2BvEpwABII11WjwfxoAAAAABJRU5ErkJggg==) no-repeat 317px top;
	color: #fff;
	padding: 25px 60px 15px;
	text-transform: uppercase;
}

.events-field {
	@include box-sizing;
	background: #7e8f96;
	border: 1px solid #bbc3c6;
	border-top-color: #616e73;
	color: #fff;
	width: 100%;
}

.events-field-text {
	padding: 4px 7px 2px;
}

.events-field-text-marginleft {
	margin-left: 5px;
	width: 100px;
	width: 10rem;
}

.events-field-text-marginright {
	margin-right: 5px;
	width: 100px;
	width: 10rem;
}

.events-label {
	display: block;
	margin-bottom: 3px;
}

.events-field-select {
	@include box-sizing;
	padding: 0 10px;
	text-transform: uppercase;  /* für Chrome */

	option {
		padding: 2px;

		&:hover {
			background: $cyan;
		}
	}
}

.events-submit {
	@extend .arrow-small-white-right;
	color: #fff;
	float: none;
}

.events-filter-radio-margin {
	display: inline-block;
	margin: 0 20px 0 5px;
	width: 90px;
}

.events-filter-radio-date-wrapper {
	display: none;

	.js & {
		display: block;
	}
}

// ***** Frontenderfassung Termine **********************************
.create-event-wrapper {
	margin-bottom: 40px;

	textarea {

	@include border-radius(5px);
	@include box-shadow(2px, 2px, 2px, 0, #cecece, true);
    background: #f2f2f2;
	border: 1px solid #f2f2f2;
	height: 100px;
	height: 10rem;
	@include box-sizing;
	display: block;
	outline: none;  /* für Chrome */
	padding: 5px 5px 3px 5px;
	width: 100%;
	}
}

.form-submit-events {
	@extend .form-submit;
	margin-left: 5px;
	padding-top: 3px;
	&:hover, &:focus {
		text-decoration: underline;
	}
}

.form-submit-back-events {
	@extend .arrow-small-cyan-left;
	float: left;
	margin-left: 5px;
	padding-left: 18px;
	padding-top: 3px;
}
