// Stile für die Teaser auf der Kapiteleinstiegsseite
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


.entryteaser {
	border-top: 1px solid #fff;
	height: 215px;
	overflow: hidden;

	.entryteaser-link:first-child & {
		border-top: none;

		.twocols-col-left & {
			@include border-radius-position(top-left, 15px);
		}

		.twocols-col-right & {
			@include border-radius-position(top-right, 15px);
		}
	}
}

.entryteaser-content {
	height: 100%;
	padding-right: 40px;
}

.entryteaser-link {
	color: inherit;
	text-decoration: none;

	&:hover, &:active, &:focus {
		.entryteaser-subheader,
		.entryteaser-text {
			display: block;
		}

		.entryteaser-header {
			background-position: 350px center !important;
		}
	
		.entryteaser-header-arrow {
			background: none;
		}
	
		.entryteaser-color-brown {
			.entryteaser-content {
				.no-cssgradients & {
					background: $leadstoryGradientBottomBrown;
				}

				@include linear-gradient-rgba($leadstoryGradientTopBrown, $leadstoryGradientBottomBrown, 0.94);

				.no-rgba & {
					@include linear-gradient($leadstoryGradientTopBrown, $leadstoryGradientBottomBrown);
				}
			}

			.entryteaser-header {
				@extend .arrow-big-brown-right;
				background-color: $barColorBrown;
			}
		}
		
		.entryteaser-color-green {
			.entryteaser-content {
				.no-cssgradients & {
					background: $leadstoryGradientBottomGreen;
				}

				@include linear-gradient-rgba($leadstoryGradientTopGreen, $leadstoryGradientBottomGreen, 0.94);

				.no-rgba & {
					@include linear-gradient($leadstoryGradientTopGreen, $leadstoryGradientBottomGreen);
				}
			}

			.entryteaser-header {
				@extend .arrow-big-green-right;
				background-color: $barColorGreen;
			}
		}
		
		.entryteaser-color-grey {
			.entryteaser-content {
				.no-cssgradients & {
					background: $leadstoryGradientBottomGrey;
				}

				@include linear-gradient-rgba($leadstoryGradientTopGrey, $leadstoryGradientBottomGrey, 0.94);

				.no-rgba & {
					@include linear-gradient($leadstoryGradientTopGrey, $leadstoryGradientBottomGrey);
				}
			}

			.entryteaser-header {
				@extend .arrow-big-grey-right;
				background-color: $barColorGrey;
			}
		}
		
		.entryteaser-color-orange {
			.entryteaser-content {
				.no-cssgradients & {
					background: $leadstoryGradientBottomOrange;
				}

				@include linear-gradient-rgba($leadstoryGradientTopOrange, $leadstoryGradientBottomOrange, 0.94);

				.no-rgba & {
					@include linear-gradient($leadstoryGradientTopOrange, $leadstoryGradientBottomOrange);
				}
			}

			.entryteaser-header {
				@extend .arrow-big-orange-right;
				background-color: $barColorOrange;
			}
		}
		
		.entryteaser-color-purple {
			.entryteaser-content {
				.no-cssgradients & {
					background: $leadstoryGradientBottomPurple;
				}

				@include linear-gradient-rgba($leadstoryGradientTopPurple, $leadstoryGradientBottomPurple, 0.94);

				.no-rgba & {
					@include linear-gradient($leadstoryGradientTopPurple, $leadstoryGradientBottomPurple);
				}
			}

			.entryteaser-header {
				@extend .arrow-big-purple-right;
				background-color: $barColorPurple;
			}
		}
		
		.entryteaser-color-red {
			.entryteaser-content {
				.no-cssgradients & {
					background: $leadstoryGradientBottomRed;
				}

				@include linear-gradient-rgba($leadstoryGradientTopRed, $leadstoryGradientBottomRed, 0.94);

				.no-rgba & {
					@include linear-gradient($leadstoryGradientTopRed, $leadstoryGradientBottomRed);
				}
			}

			.entryteaser-header {
				@extend .arrow-big-red-right;
				background-color: $barColorRed;
			}
		}
	}
}

.entryteaser-header {
	@include border-radius-position(bottom-right, 15px);
	background: $cyan;
	color: #fff;
	line-height: 100%;
	padding: 22px 0 18px 51px;
	text-transform: uppercase;
}

.entryteaser-header-arrow {
	background-position: right top;
	display: inline-block;	/* für Chrome und Opera */
	padding-right: 49px;
}

.entryteaser-subheader,
.entryteaser-text {
	display: none;
	font-family: DINWeb, Arial, Helvetica, sans-serif;
	margin: 15px 0 0 51px;
}

.entryteaser-subheader {
	@include font-size(18);
	@include line-height(22);
	color: #fff;
	font-weight: normal;
}

.entryteaser-text {
	@include font-size(14);
	@include line-height(18);
	color: #000;
}

.entryteaser-placeholder {
	background: rgba(#009ee3, 0.5);

	.no-rgba & {
		background: url(../../Public/Images/StyleSheets/no-rgba-009ee3-50.png);
	}
}
