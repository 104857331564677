
// Wetter Stile
//
// @author Lars Beuse <lars@cyperfection.de>
// @version $Id$
// @package ludwigshafen

@each $statuscode in  0, 1, 2, 3, 4, 5, 6, 7, 8, 9 {
	.weather-statuscode-#{$statuscode} {
		background: url('/weather_icons/d_#{$statuscode}_b.gif') no-repeat 80px -7px;
	}
}

@each $statuscode-evening in  0, 1, 2, 3, 4, 5, 6, 7, 8, 9 {
	.weather-statuscode-evening-#{$statuscode-evening} {
		background: url('/weather_icons/n_#{$statuscode-evening}_b.gif') no-repeat 80px -7px;
	}
}

.weather-wrapper {
	width: 855px;
	margin:0 auto;
	height:100px;
}

.weather-wrapper-header {
    float:left;
	width: 340px;
	margin: 0;
	padding: 0;
}

.weather-wrapper-daytime-morning {
	float: left;
	margin: 0;
	padding: 0;
	width: 170px;
}

.weather-wrapper-daytime-noon {
	float: left;
	margin: 0;
	padding: 0;
	width: 170px;
}

.weather-wrapper-daytime-evening {
	float: right;
	margin: 0;
	padding: 0;
	width: 170px;
}


.weather-statustext {
	float: left;
	width: 140px;
}

.weather-image {
	float: right;
	width: 100px;
}

.weather-credits {
	@include font-size(10);
	float: right;
	padding-top:10px;

	a {
		text-decoration:none;

		&:hover {
			text-decoration: underline;
		}
	}
}
