// Einbindung der Schriften
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


// ***** DIN Web Regular *******************************************************
@font-face {
	font-family: 'DINWeb';
	src: url('../../Public/Fonts/DINWeb.eot'); /* IE9 Compat Modes */
	src: url('../../Public/Fonts/DINWeb.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../../Public/Fonts/DINWeb.woff') format('woff') /* Modern Browsers */
}

// ***** DIN Web Light *********************************************************
@font-face {
	font-family: 'DINWeb-Light';
	src: url('../../Public/Fonts/DINWeb-Light.eot'); /* IE9 Compat Modes */
	src: url('../../Public/Fonts/DINWeb-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../../Public/Fonts/DINWeb-Light.woff') format('woff') /* Modern Browsers */
}

// ***** DIN Web Medium ********************************************************
@font-face {
	font-family: 'DINWeb-Medium';
	src: url('../../Public/Fonts/DINWeb-Medium.eot'); /* IE9 Compat Modes */
	src: url('../../Public/Fonts/DINWeb-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../../Public/Fonts/DINWeb-Medium.woff') format('woff') /* Modern Browsers */
}

// ***** DIN Web Bold **********************************************************
@font-face {
	font-family: 'DINWeb-Bold';
	src: url('../../Public/Fonts/DINWeb-Bold.eot'); /* IE9 Compat Modes */
	src: url('../../Public/Fonts/DINWeb-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../../Public/Fonts/DINWeb-Bold.woff') format('woff') /* Modern Browsers */
}
