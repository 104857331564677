// Stile für das Input-Modul
//
// @author Lars Beuse <lars@cyperfection.de>
// @version $Id$
// @package ludwigshafen



// ***** Teaser Startseite *****************************************************
.inputmodule-wrapper {
	display: block;
	background: url(../../Public/Images/StyleSheets/speech-bubble.png) no-repeat;
	min-height: 101px;
	padding-left: 120px;
	position: relative;
}
