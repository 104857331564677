// Stile für den Krisenfall
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


.emergency {
    @include border-radius(15px);
    background: $barColorRed;
	@include linear-gradient($leadstoryGradientTopRed, $leadstoryGradientBottomRed);
	color: #fff;
	margin: 20px 0 60px;
	padding: 20px 51px 10px;

	.h1 {
		color: #fff;
	}
}
