// Druckversion und PDF Version
//
// @author Lars Beuse <lars@cyperfection.de>
// @version $Id$
// @package ludwigshafen


.no-print {
	display: none;
}

body {
	margin-top: 0.5cm;
}

body,
.stage-outer {
	background: none !important;
}

.abstract {
	@include font-size(14);
	color: #000;
	font-weight: bold;
}

.content-area-content-main {
	width: auto;
}

.content-area-door {
	background: none !important;
}

.content-area-door-main {
	margin-top: 0;
}

.h1,
.h2 {
	color: #000;
	font-weight: bold;
}

.logo-additional-print {
	display: inline-block;
	float: right;
	margin: -15px 0 0 30px;
}

.site-center {
	margin: 0;
	width: auto;
}

.stage-outer-small-padding {
	padding-top: 0;
}

.bullet-list {
	list-style-type: square;

	li {
		// @extend .link-list-item;
	}
}

.link-list-item {
	background: none;
	padding-left: 0;
}
