@import "../Vendor/fancybox-2.1.7/source/jquery.fancybox";

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
	background-image: url(../Images/fancybox/fancybox_sprite.png);
}


#fancybox-loading div {
	background: url(../Images/fancybox/fancybox_loading.gif) center center no-repeat;
}

.fancybox-nav {
	background: transparent url(../Public/Images/fancybox/blank.gif); /* helps IE */
}


/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5){

	#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
		background-image: url(../fancybox/fancybox_sprite@2x.png);
	}

	#fancybox-loading div {
		background-image: url(../Images/fancybox/fancybox_loading@2x.gif);
	}
}
